$main-background-color: #1b2431;
$bottom-line-color: hsla(0, 0%, 85%, 0.062);
// variables
$card-background-color: #273142;
$card-border-color: #313d4f;
$card-border-radius: 8px;
$main-text-color: #ffffff;
$secondary-text-color: #d7d5d5;
$confirm-color: #1bb99a;
$cancel-color: #ff4f4f;
$callback-color: #0088ff;
$postpone-color: #a436ac;
$main-padding: 30px;
$secondary-padding: 27px 21px 10px 21px;
$table-th-td-padding: 10px;
// functions
@mixin buttonEffect($backgroundColor) {
  background-color: $backgroundColor;

  &:hover {
    background-color: lighten($backgroundColor, 10%);
    transition: 0.2s ease-in-out;
    transform: scale(1.1);
  }
}

@mixin onHoverBorderEffect {
  &:hover {
    transition: 0.3s ease-in-out;
    border: 2px solid #0088ff;
  }
}
// Styles
.CallSessionRefactored {
  width: 100vw;
  background-color: $main-background-color;

  main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h4 {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
    & > section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      height: 100%;
      padding: $main-padding;
    }
    article {
      background-color: $card-background-color;
      border: 1px solid $card-border-color;
      border-radius: $card-border-radius;
      & > h4 {
        border-bottom: 1px solid $bottom-line-color;
        padding: 27px 21px 10px 21px;
      }
    }
    .CallSession_productsAndOrders {
      .productDetails {
        height: 325px;
        width: clamp(300px, 995px, 70vw);
        margin-right: 10px;
        margin-bottom: 30px;
        @include onHoverBorderEffect();
        .productDetails_productsList {
          display: flex;
          flex-direction: column;
          align-items: center;

          height: 75%;
          overflow-y: scroll;
          scrollbar-color: rebeccapurple green;
          scrollbar-width: thin;
          padding: $secondary-padding;

          .productList_product {
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
            svg {
              font-size: 22px;
              color: #fff;
              cursor: pointer;
              &:hover {
                color: darken(#fff, 30%);
              }
            }

            .productList_product_quantity {
              display: flex;
              align-items: center;
              font-size: 35px;
              width: 100%;
              .productQuantityInput {
                background-color: transparent;
                input {
                  padding-left: 14px;
                  width: 70px;
                  color: red;
                  background-color: red;
                }
              }
              .quantity_operations {
                display: flex;
                // flex-direction: column;
                justify-content: space-between;
                margin-left: 20%;
                align-items: center;
                width: 100%;
                & > svg {
                  font-size: 40px;
                }
              }
            }
            .productList_product_description {
              label {
                font-size: 18px;
              }
              & > p {
                height: 100px;
                overflow-y: scroll;
              }
            }
          }
        }
      }
      .ordersDetails {
        height: 345px;
        width: clamp(300px, 355px, 10vw);
        margin-bottom: 30px;
        @include onHoverBorderEffect();
        .ordersDetails_container {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: $secondary-padding;
          padding-top: 10px;
          & > section {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .ordersDetails_price {
            .priceInput {
              label {
                color: #fff;
              }
              input {
                padding-left: 30%;
                color: #fff;
                // padding-right: 50%;
              }
              .priceInput_DA {
                p {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    .CallSession_call {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: $card-background-color;
      border: 1px solid $card-border-color;
      border-radius: $card-border-radius;
      @include onHoverBorderEffect();
      #dial-phone-number {
        color: #fff;
        &:hover {
          color: $callback-color;
        }
      }
      .customerInfo {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 0;
        border: none;
        .customerInfo_picture {
          margin-right: 20px;
        }

        b {
          font-size: large;
        }
        h4 {
          font-size: small;
        }
      }
      .callButton {
        border: none;
        background: none;
        border-radius: 50%;
        outline: none;
        &:hover {
          transition: 0.2s ease-in-out;
          opacity: 0.8;
        }
      }
    }
    .CallSession_deliveryAndActions {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .deliveryDetails {
        width: clamp(300px, 995px, 70vw);
        // height: 430px;
        margin-bottom: 30px;
        margin-right: 10px;
        padding-bottom: 10px;
        @include onHoverBorderEffect();
        .deliveryDetails_container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          height: 80%;
          .deliveryDetails_left {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            min-width: 300px;
            width: 70%;
            padding: $secondary-padding;
            .input_textfield {
              width: 100%;
              input {
                height: 40px;
                padding-left: 10px;
              }
              label {
                color: #fff;
              }
            }
            .input_autocomplete {
              width: 40%;
              min-width: 250px;
              label {
                color: #fff;
              }
              svg {
                color: #fff;
              }
            }
            & > section {
              margin-bottom: 10px;
            }
            .address_container {
              width: 100%;
              & > label {
                color: $cancel-color;
              }
            }
            .wilaya_commune_container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              width: 100%;
              & > label {
                color: $cancel_color;
              }
            }
          }

          .deliveryDetails_right {
            padding: $secondary-padding;
            textarea {
              height: 150px;
            }
          }
        }
      }
      .actions {
        display: flex;
        flex-direction: column;
        height: 430px;
        width: clamp(300px, 355px, 10vw);
        @include onHoverBorderEffect();
        .actions_buttonContainer {
          display: flex;
          flex-direction: column;
          align-content: space-between;
          height: 90%;
          justify-content: space-between;
          padding: $secondary-padding;
          padding-bottom: 21px;
          & > button {
            height: 50px;
            outline: none;
            border: none;
            border-radius: 8px;
            color: #fff;
            font-size: 18px;
          }
          #confirm-button {
            @include buttonEffect($confirm-color);
          }
          #cancel-button {
            @include buttonEffect($cancel-color);
          }
          #callback-button {
            @include buttonEffect($callback-color);
          }
          #postpone-button {
            @include buttonEffect($postpone-color);
          }
        }
      }
    }
  }

  footer {
    padding: 10px;
    .CallSession_orderSearch {
      .searchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .pospontedOrderSearchInput {
          label {
            color: #fff;
            width: 200px;
            padding: 5px;
            padding-left: 10px;
          }
        }
        button {
          width: 100px;
          height: 40px;
          color: #fff;
          border: none;
          border-radius: 8px;
          margin-left: 10px;
          font-size: 18px;
          font-weight: bold;
          font-family: Nunito Sans;
          background-color: #007bff;
          transition: 0.2s ease-in-out;
          &:hover {
            background-color: lighten(#007bff, 10%);
          }
        }
      }
      .postponedOrdersContainer {
        width: 100%;
        table {
          width: 100%;
          border-collapse: collapse;
          thead {
            background-color: hsl(216, 22%, 35%);
            tr {
              th {
                padding: $table-th-td-padding;
              }
            }
          }
          tbody {
            tr {
              border: 1px solid #444444;
              background-color: hsl(218, 26%, 21%);
              cursor: pointer;
              &:hover {
                background-color: hsl(218, 26%, 31%);
              }
              td {
                padding: $table-th-td-padding;
                .statusColorCircle {
                  width: 15px;
                  height: 15px;
                  border-radius: 50%;
                  margin-right: 5spx;
                }
              }
            }
          }
        }
      }
    }
  }

  .noMoreOrdersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    & > svg {
      width: 100%;
    }
    h3 {
      text-align: center;
    }
  }
}

#confirm-button:disabled {
  opacity: 0.5;
}
