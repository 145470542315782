.uploadOrders_form {
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &:first-child {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex: 0.8;
      padding: 10px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0.2;
      padding: 10px;
      & > button {
        width: 100px;
        height: 40px;
        padding: 8px;
        color: #fff;
        border-radius: 7px;
        border: none;
        width: 30%;
        background-color: #007bff;
        &:hover {
          background-color: lighten(#007bff, 10%);
        }
      }
    }
  }
}
.adminImportOrders {
  display: flex;
  // align-items: center;
  // justify-content: space-evenly;
  flex-direction: column;
  padding: 10px;

  .backendErrorMessage,
  .importErrorMessage {
    width: 90%;
    height: auto;
    align-self: center;
    color : #f32626;
    font-size: 19px;
    letter-spacing: 0.1rem;
  }
}
