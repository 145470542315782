$main-background-color: #1b2431;
$bottom-line-color: hsla(0, 0%, 85%, 0.062);
// variables
$card-background-color: #273142;
$card-border-color: #313d4f;
$card-border-radius: 8px;
$main-text-color: #ffffff;
$secondary-text-color: #d7d5d5;
$confirm-color: #1bb99a;
$cancel-color: #ff4f4f;
$callback-color: #0088ff;
$postpone-color: #a436ac;
$main-padding: 30px;
$secondary-padding: 27px 21px 10px 21px;
$table-th-td-padding: 10px;

.postponeOrder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 350px;
  background-color: $card-background-color;
  border: 1px solid $card-border-color;
  color: #fff;
  .postponeOrder_top {
    padding: 10px;
    h4 {
      color: #fff;
    }
  }

  .postponeOrder_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    height: 80%;

    .postponeDate_datePicker {
      // width: 100%;
      min-width: 250px;

      label {
        color: #fff;
      }
     
    }
    & > label {
      color: $cancel-color;
    }
    & > button {
      width: 80%;
      height: 50px;
      background-color: $postpone-color;
      border-radius: 8px;
      color: #fff;
      border: none;
      outline: none;
      font-size: 20px;
      &:hover {
        background-color: lighten($postpone-color, 10%);
        transition: 0.2s ease-in-out;
      }
    }
  }
}
