:root{
    --wdt:500px;
    --hgt:auto;
}
.StLyt{
    border-radius: 24px;
    border: 0.3px solid #B9B9B9;
    padding: 19px 50px;
}
.SetTopbd{
    padding: 70px 0;
}
.StMev{
    width: var(--wdt)
}
@media screen and (max-width: 550px){
    :root{
        --wdt:90%;
    } 
    .StMev{
        width: calc(90% - 100px);
    }
}