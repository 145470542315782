.dashboard {
  display: flex;
  flex-direction: column;
  .dashboard_top {
    margin: 20px 30px 20px 30px;
  }
  .adminLadingPage {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px 30px 20px 30px;
    .adminActionCard {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 10px;
      padding: 7px 10px 7px 10px;
      max-width: 250px;
      border: 1px solid #323d4e;
      background-color: lighten(#323d4e, 2%);
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: lighten(#323d4e, 10%);
      }
      .adminActionCard_icon {
        & > svg {
          font-size: 20px;
          width: 150px;
          height: 150px;
        }
      }
      .adminActionCard_content {
        margin-top: 10px;
        font-size: 20px;
      }
    }
  }
}
