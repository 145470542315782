
@mixin inCommonButtons {
  width: 100px;
  height: 40px;
  padding: 8px;
  color: #fff;
  background-color: #1bb99a;
  border-radius: 7px;
  border: none;
}
.assignOrders {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .assignOrderModal_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        z-index: 1000;
      
      width: 90%;
      height: 70%;
      background-color: lighten(#323d4e, 5%);
      border-radius: 10px;
      z-index: 1100;
      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #323d4e;
        padding: 10px;
        h3 {
          font-size: 18px;
        }
        span {
          font-size: 10px;
          cursor: pointer;
        }
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
  
        .agentDropDownList {
          display: flex;
          align-items: center;
          align-content: space-between;
          justify-content: space-around;
          flex-wrap: wrap;
          width: 50%;
          // height : 100px;
          & > input {
              margin-bottom: 10px;
              padding-left: 10px;
          }
        }
        .errorMessage {
          margin-top: 10px;
          max-height: 200px;
          max-width : 95%;
          padding: 10px;
          color : #f32626;
          font-size: 18px;
          overflow-y: scroll;
        }
        button {
          @include inCommonButtons();
          width: 30%;
          margin-top: 10px;
          background-color: #007bff;
          &:hover {
            background-color: lighten(#007bff, 10%);
          }
        }
      }
    }
  }