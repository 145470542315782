/**
    Override bootstrap styles
**/
.dropdown *[aria-haspopup="true"]{
    color: var(--fntClr) !important
}
a, a *{
    text-decoration: none !important;
    color: var(--fntClr);
}
input,.form-control{
    background: var(--bdgclInp) 0% 0% no-repeat padding-box !important;
    border: 1px solid var(--clrbrd) !important;
    text-align: var(--pslan) !important;
    font-size: var(--sizFnt) !important;
    color: var(--fntClr) !important;
    
}
/**
    Override data grid styles
**/
.rgd {
    border-radius: 14px;
    background-color: var(--bdgclInp);
}

.rdg-header-row {
    text-transform: capitalize;
    /* text-align: center; */
    font-size: large;
    font-weight: bold;
    background: var(--bdgclInp);

}
.rdg-row {
    background:  var(--defcl);
    
}
.rdg-header-row,.rdg-row {
    color: var(--fntClr);
    height: auto !important;
    min-height: 40px !important;
}
.rdg-header-row:hover, .rdg-row:hover {
    background-color: var(--bdgclInp);
}
.rdg-cell {
    border-left: none;
    border-right: none;
}


/**
    Custom styles
**/
.call_act_btn svg {
    width: 60%;


}
.confirm-modal .modal-content {
    background-color: var(--defcl);
    border-radius: 8px;

}
.custSelect {
    background: var(--bdgclInp) 0% 0% no-repeat padding-box !important;

}
.centerInput {
    text-align: center !important;
}
