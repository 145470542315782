.pagination_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  .pagination_info {
    span {
      color: #ffffff;
      opacity: 0.6;
      font-size: 13px;
    }
  }
  .pagination_bar {
    .MuiPagination-root {
      .MuiPagination-ul {
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        width: auto;
        li {
        }
      }
    }
    .MuiPaginationItem-root {
      color: #adb1b8;
      .MuiPaginationItem-icon {
        color: #ffffff;
      }
    }
  }
  ul {
    margin-left: 10px;
  
    li {

      button {
        // background-color: lighten(#1976d2, 18%);
        color: #fff;
      }
    }
  }
}
