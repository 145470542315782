$side_padding: 20px;
$confirm_color: #1bb99a;
$cancel_color: #dc3545;
$callBack_color: #007bff;
$pending_color: #a436ac;

.admin_addAgent {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 10px;
  & > h2 {
    align-items: flex-end;
    width: 100%;
  }
  .admin_addAgent_addForm {
    width: 100%;
  }
  .admin_addAgent_agentListTable {
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    width: 100%;
    overflow-x: scroll;
    .admin_addAgent_agentListTable_top{
      align-self: flex-start;
      margin-bottom: 20px;
    }
    & > h2 {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      align-self: flex-start;
    }
    table {
      width: 100%;
      thead {
        background-color: #323d4e;
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
            &:nth-child(5) {
              width: 200px;
            }
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #444444;
          background-color: #273142;
          &:hover {
            background-color: lighten(#273142, 10%);
          }
          td {
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
          }
          .agentListaTable_statsColumn {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 200px;
            & > figure {
              & > figcaption {
                padding-top: 20px;
                & > mark {
                  background-color: #e77b0ef5;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
