$side_padding: 20px;
$global_padding: 10px;
@mixin inCommonButtons {
  width: 100px;
  height: 40px;
  padding: 8px;
  color: #fff;
  background-color: #1bb99a;
  border-radius: 7px;
  border: none;
}
.admin_ordersPage_refactored {
  width: 100vw;
  height: 100%;
  padding: $global_padding;
  main {
    .admin_ordersPage_actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;

      .actions_filter {
        width: 100%;
        min-width: 290px;
        form {
          display: flex;
          // align-items: center;
          justify-content: space-between;
          // flex-direction: column;
          flex-wrap: wrap;
          width: 100%;
          & > article {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
            width: 80%;
            .filterInput {
              width: 290px;
              height: 51px;
              background-color: #fff !important;
              border-radius: 5px;
              padding-left: 10px;
              &::placeholder {
                color: #000;
              }
            }
            input {
              margin-bottom: 10px;
            }
            input,
            .form-control {
              background: transparent !important;
              color: #000 !important;
            }

            .reactSelect,
            .reactSelect-status {
              width: 290px;
              color: #000;
              margin-bottom: 10px;
            }
            .reactSelect-status {
              width: 290px;
              .css-1hwfws3 {
                max-height: 50px;
              }
            }
          }

          button[type="submit"] {
            @include inCommonButtons();
            background-color: #007bff;
            &:hover {
              background-color: lighten(#007bff, 10%);
            }
          }
        }
      }
      .actions_buttonContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // flex-direction: row-reverse;
        margin-top: 10px;
        width: 100%;
        // height: 100%;
        .assignButton {
          @include inCommonButtons();
          margin-right: 10px;
          background-color: #1bb99a;
          &:hover {
            background-color: lighten(#1bb99a, 10%);
          }
        }
        .exportButton {
          @include inCommonButtons();
          background-color: #007bff;
          &:hover {
            background-color: lighten(#007bff, 10%);
          }
        }
      }
    }
  }
  .adminOrders_tableContainer {
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    header {
      width: 100%;
      margin-bottom: 10px;
    }
    table {
      width: 100%;
      thead {
        background-color: #323d4e;
        tr {
          th {
            font-weight: normal;
            font-size: 18px;
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
          }
        }
      }
      tbody {
        tr {
          border: 1px solid #444444;
          background-color: #273142;
          &:hover {
            background-color: lighten(#273142, 10%);
          }
          td {
            padding: 10px;
            padding-left: $side_padding;
            padding-right: $side_padding;
          }
        }
      }
    }
  }
}
