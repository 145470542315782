.whiteText {
  color: white;
  background-color: #273142;
}

.input {
  border: "1px";
  border-color: transparent;
  background-color: #273142;
  color: white;
  width: 100%;
}

@media (max-width: 468px) {
  .input-password-mobile.input {
    border: "1px";
    border-color: transparent;
    background-color: #273142;
    color: white;
    width: 100%;
  }
}

@media (max-width: 280px) {
  .input-password-mobile-small.input-password-mobile {
    border: "1px";
    border-color: transparent;
    background-color: #273142;
    color: white;
    width: 100%;
  }
}
